<template>
  <div class="wrap">
    <div class="search-container">
      <el-form :model="form" label-width="80px">
        <el-row>
          <el-col :md="20">
            <el-row :gutter="48">
              <el-col :md="8" :sm="15">
                <el-form-item label="登录地址">
                  <el-input
                    clearable
                    placeholder="请输入"
                    v-model="form.ipaddr"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="15">
                <el-form-item label="登录名称">
                  <el-input
                    clearable
                    placeholder="请输入"
                    v-model="form.loginName"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="登录状态">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.status"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(d, index) in commonStatus"
                      :key="index"
                      :value="d.dictValue"
                      :label="d.dictLabel"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="登录时间">
                  <el-date-picker
                    style="width: 100%"
                    v-model="form.range"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="daterange"
                    clearable
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :md="4" :sm="12" class="text-right">
            <el-button @click="searchEvent" class="search-button">{{
              $t("search")
            }}</el-button>
            <el-button @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="infoId"
          align="center"
          label="访问编号"
          width="110"
        >
        </el-table-column>
        <el-table-column
          prop="loginName"
          align="center"
          label="登录名称"
          width="133"
        >
        </el-table-column>
        <el-table-column
          prop="ipaddr"
          align="center"
          label="登录地址"
          min-width="140"
        >
        </el-table-column>
        <el-table-column
          prop="loginLocation"
          align="center"
          label="登录地点"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="browser"
          align="center"
          label="浏览器"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="os" align="center" label="操作系统" width="135">
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态" width="95">
          <template slot-scope="scope">
            <span>{{ scope.row.status | statusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          align="center"
          label="操作信息"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          prop="loginTime"
          align="center"
          label="操作时间"
          sortable="custom"
          width="180"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="page-wrapper">
      <div class="">
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('monitor:logininfor:remove')"
          @click="cleanList"
          >清空日志</el-button
        >
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import { getDictArray } from "@/unit/dict";
import UserSearchClass from "./userSearchClass";

export default {
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      commonStatus: [],
    };
  },
  async created() {
    const commonStatus = await getDictArray.call(this, "sys_common_status");
    this.commonStatus = commonStatus.data;
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "失败",
        0: "成功",
      };
      return statusMap[status];
    },
  },
  computed: {
    ...mapState(["Language"]),
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      const formData = {
        ...this.form,
      };
      console.log(formData);
      if (formData.range) {
        if (formData.range.length > 0) {
          formData.beginTime = this.form.range[0];
          formData.endTime = this.form.range[1];
        }
      } else {
        formData.beginTime = "";
        formData.endTime = "";
      }
      this.$http
        .get(apis.logininfor_List, {
          params: formData,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 清空列表
     */
    cleanList() {
      this.$http.post(apis.logininfor_clean).then((res) => {
        if (res.data.code === 0) {
          this.searchEvent();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.table-container {
  margin-top: 0;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
