import apis from '@/apis'
/**
 * 根据类型获取字典内容 返回数组
 * @param {String} dictType 字典类型
 */
/* eslint-disable */
export const getDictArray = function (dictType) {
  return this.$http.get(apis.dictData_type, { params: { 'dictType': dictType } })
}

export default {
  Service: [
    'SPACE_RESERVATE_INTERVAL',
    'BUSINESS_TIME',
    'ONLINE_SHOP_DISPLAY',
    'TICKET_LIST_STATUS',
  ],
  mustSeeSpot: [
    'OM_VR_SUPPORT',
  ],
}
