import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.ipaddr = ''
      this.loginName = ''
      this.status = ''
      this.range = ''
      this.sortField = 'loginTime'
      this.sortOrder = 'desc'
    }
  }
}
export default searchFrom
